import httpInternal from '@/http/httpInternal';
import isPlainObject from 'lodash/isPlainObject';
import assign from 'lodash/assign';
import consoleLog from '@/assets/js/helpers/console-log';
import {
  CUSTOMER_TYPES,
  FINANCING_OPTIONS, LEASING_KEYS, LEASING_SETTINGS, LOAN_KEYS, LOAN_SETTINGS,
} from '@/constants/constants-financing';
import { CONFIGURATOR_STEPS, ORDER_INSTIGATOR } from '@/constants/constants-configurator';
import { getShareConfigUrl } from '@/assets/js/helpers/configurator-helpers';
import {
  DEFAULT_SETTINGS_LEASING,
  DEFAULT_SETTINGS_LOAN,
} from '@/stores/settings/financing';
import { roundNumber } from '@/assets/js/helpers/general-helpers';
import { STOCK_CARS_ORDER_ERROR_REASONS } from '@/constants/constants-stock-cars';

const INITIAL_OPENED_ORDER_STATE_ITEM = () => ({
  isCompleted: false, // @todo Should probably be "canBeEdited" or similar when it actually works
  orderId: null,
  userUuid: null,
  dealerNumber: null,
  modelId: null,
  marketModelId: null,
  selections: [],
  preReservationId: null,
  orgNumber: null,
  companyName: null,
  numberOfCars: null,
  numberOfReservations: null,
  salespersonName: null,
  createdBy: null,
  updatedBy: null,
  firstName: null,
  lastName: null,
  mobileNumber: null,
  email: null,
  addressLine: null,
  zipCode: null,
  city: null,
  customerComment: null,
  orderInformationShown: false,
});

const mergeCategories = ({ orderCategories, orderSelections, mmCategories }) => {
  const mergedCategories = mmCategories || {};
  orderCategories.forEach((orderCategory) => {
    if (
      !mergedCategories[orderCategory.id]
      && orderSelections.find((x) => x.categoryId === orderCategory.id) !== undefined
    ) {
      mergedCategories[orderCategory.id] = orderCategory;
    }
  });
  return mergedCategories;
};

const formatOrderWltpData = (wltpData) => {
  const wltpObject = { ...wltpData };

  wltpData?.wltp?.forEach((entry) => {
    wltpObject[entry.key] = {
      value: entry.value,
      unit: entry.unit,
    };
  });

  delete wltpObject.wltp;

  return wltpObject;
};

const OrdersStore = {
  state: {
    openedOrderStates: {},
    verificationInProgress: false,
  },
  getters: {
    getOpenedOrderState: (state, getters) => (
      openedOrderStateId = getters.getActiveModelId,
    ) => state.openedOrderStates[openedOrderStateId],

    isChangingOrder: (state, getters) => (
      openedOrderStateId = getters.getActiveModelId,
    ) => !!getters.getOpenedOrderState(openedOrderStateId)?.orderId,

    isOpenedOrderCompany: (state, getters) => (
      openedOrderStateId = getters.getActiveModelId,
    ) => !!getters.getOpenedOrderState(openedOrderStateId)?.orgNumber,

    getOpenedOrderLastInstigator: (state, getters) => (
      openedOrderStateId = getters.getActiveModelId,
    ) => {
      const { createdBy, updatedBy } = getters.getOpenedOrderState(openedOrderStateId);
      return updatedBy || createdBy;
    },

    shouldShowOpenedOrderInformation: (state, getters) => (
      openedOrderStateId = getters.getActiveModelId,
    ) => {
      if (!getters.isChangingOrder(openedOrderStateId)) return false;
      if (getters.getOpenedOrderState(openedOrderStateId)?.orderInformationShown) return false;

      return getters.getOpenedOrderLastInstigator(openedOrderStateId) !== ORDER_INSTIGATOR.customer;
    },

    shouldShowNumberOfCars: (state, getters) => (
      openedOrderStateId = getters.getActiveModelId,
    ) => getters.getOpenedOrderState(openedOrderStateId)?.numberOfReservations > 1,

    isVerificationInProgress: (state) => state.verificationInProgress,
  },
  mutations: {
    UPDATE_OPENED_ORDER_STATE(state, {
      openedOrderStateId,
      openedOrder = INITIAL_OPENED_ORDER_STATE_ITEM(),
    } = {}) {
      const orderStateItem = !isPlainObject(openedOrder)
        ? INITIAL_OPENED_ORDER_STATE_ITEM()
        : assign(INITIAL_OPENED_ORDER_STATE_ITEM(), openedOrder);

      this._vm.$set(state.openedOrderStates, openedOrderStateId, orderStateItem);
    },
    UPDATE_OPENED_ORDER_NUMBER_OF_CARS(state, {
      openedOrderStateId,
      numberOfCars = 1,
    } = {}) {
      if (!state.openedOrderStates[openedOrderStateId]) return;
      this._vm.$set(state.openedOrderStates[openedOrderStateId], 'numberOfCars', numberOfCars);
    },
    UPDATE_OPENED_ORDER_CUSTOMER_COMMENT(state, {
      openedOrderStateId,
      customerComment = '',
    } = {}) {
      if (!state.openedOrderStates[openedOrderStateId]) return;
      this._vm.$set(state.openedOrderStates[openedOrderStateId], 'customerComment', customerComment);
    },
    UPDATE_OPENED_ORDER_INFORMATION_SHOWN(state, {
      openedOrderStateId,
      orderInformationShown = false,
    } = {}) {
      if (!state.openedOrderStates[openedOrderStateId]) return;
      this._vm.$set(state.openedOrderStates[openedOrderStateId], 'orderInformationShown', orderInformationShown);
    },
    UPDATE_VERIFICATION_IN_PROGRESS(state, verificationInProgress = false) {
      state.verificationInProgress = verificationInProgress;
    },
  },
  actions: {
    // @todo Add a closeOpenOrder action instead of using the
    // @todo UPDATE_OPENED_ORDER mutation elsewhere when resetting order

    async initOpenedOrderState({ state, commit }, openedOrderStateId) {
      if (!state.openedOrderStates[openedOrderStateId]) commit('UPDATE_OPENED_ORDER_STATE', { openedOrderStateId });
    },

    async openExistingOrder({ getters, commit, dispatch }, {
      orderId,
    } = {}) {
      dispatch('setLoader', true);

      await dispatch('fetchAndSetOrder', { orderId });

      const modelId = getters.getActiveModelId;
      const marketModelId = getters.getModelSelectedMarketModelId();

      await dispatch('validateMarketModelConfiguration', { marketModelId });

      commit('UPDATE_DEALER_RESTRICTIONS');
      dispatch('setModelCurrentConfigStep', { modelId, stepKey: CONFIGURATOR_STEPS.financing, checkInventory: false });
    },

    async fetchAndSetOrder({ commit, getters, dispatch }, { orderId }) {
      let orderResponse;
      let orderModelId;
      let orderMarketModelId;
      let orderSelections;
      let orderCategories;
      let orderGroups;

      try {
        orderResponse = await httpInternal(
          {
            method: 'get',
            url: getters.apiGetOrderUrl,
            params: {
              orderId,
              userUuid: getters.userInfo.userUuid,
            },
          },
        );
      } catch (error) {
        dispatch('setAlert', { key: 'orderError', message: 'error.getOrder', log: error });
        throw error;
      }

      const { data: order = {} } = orderResponse;

      try {
        ({ modelId: orderModelId, marketModelId: orderMarketModelId } = order);

        orderSelections = JSON.parse(order.selections);
        orderCategories = JSON.parse(order.categories);
        orderGroups = JSON.parse(order.groups);
      } catch (error) {
        dispatch('setAlert', { key: 'orderError', message: 'error.getOrder', log: error });
        throw error;
      }

      await dispatch('fetchAndSetMarketModels', { modelId: orderModelId });

      try {
        await dispatch('setConfigActiveModelId', { modelId: orderModelId, skipMarketModels: true });
      } catch (error) {
        await dispatch('resetModelConfigurator', { modelId: orderModelId });
        consoleLog(error);
        throw error;
      }

      await dispatch('resetModelConfigurator', { modelId: orderModelId, closeOpenedOrder: false });

      if (!getters.getMarketModelById(orderMarketModelId)) {
        await dispatch('resetModelConfigurator', { modelId: orderModelId });
        dispatch('setAlert', { message: 'error.openedOrderOutdated' });
        throw new Error('Opened order is outdated');
      }

      try {
        await dispatch('setModelSelectedMarketModel', { marketModelId: order.marketModelId, updateConfiguratorKey: false });
      } catch (error) {
        await dispatch('resetModelConfigurator', { modelId: orderModelId });
        consoleLog(error);
        throw error;
      }

      // Merge selections with market model options
      const marketModelOptions = getters.getMarketModelOptions({
        marketModelId: orderMarketModelId,
        ignoreFilter: true,
        associative: true,
      });

      const outdatedOptions = [];
      orderSelections.forEach((selection) => {
        const foundOption = marketModelOptions[selection.id];

        foundOption
          ? marketModelOptions[selection.id] = selection
          : outdatedOptions.push(selection);
      });

      commit('UPDATE_MARKET_MODEL_OUTDATED_SELECTIONS', {
        marketModelId: orderMarketModelId,
        outdatedSelections: outdatedOptions.filter((x) => x.accessoryType !== 'dealer-option'),
      });
      commit('UPDATE_MARKET_MODEL_DATA_OPTIONS', { marketModelId: orderMarketModelId, marketModelOptions });

      // Merge categories
      const mergedCategories = mergeCategories({
        orderCategories,
        orderSelections,
        mmCategories: getters.getMarketModelCategories({ orderMarketModelId, associative: true }),
      });
      commit('UPDATE_MARKET_MODEL_DATA_CATEGORIES', { marketModelId: orderMarketModelId, categories: mergedCategories });

      // Merge groups
      const mergedGroups = mergeCategories({
        orderCategories: orderGroups,
        orderSelections,
        mmCategories: getters.getMarketModelGroups({ orderMarketModelId, associative: true }),
      });
      commit('UPDATE_MARKET_MODEL_DATA_GROUPS', { marketModelId: orderMarketModelId, groups: mergedGroups });

      // Update selections
      const filteredSelectionsIds = orderSelections
        .filter((selection) => !outdatedOptions
          .find((outdatedOption) => outdatedOption.id === selection.id))
        .map((selection) => selection.id);
      commit('UPDATE_MARKET_MODEL_SELECTIONS', { marketModelId: orderMarketModelId, selectionIds: filteredSelectionsIds });

      // Set opened order data
      commit('UPDATE_OPENED_ORDER_STATE', {
        openedOrderStateId: orderModelId,
        openedOrder: {
          isCompleted: order.isCompleted,
          orderId: order.orderId,
          userUuid: order.userUuid,
          dealerNumber: order.dealerNumber,
          modelId: orderModelId,
          marketModelId: orderMarketModelId,
          selections: orderSelections,
          preReservationId: order.preReservationId,
          orgNumber: order.orgNumber,
          companyName: order.companyName,
          numberOfCars: order.numberOfCars,
          numberOfReservations: order.numberOfReservations,
          salespersonName: order.salespersonName,
          createdBy: order.createdBy,
          updatedBy: order.updatedBy,
          firstName: order.firstName,
          lastName: order.lastName,
          mobileNumber: order.mobileNumber,
          email: order.email,
          addressLine: order.addressLine,
          zipCode: order.zipCode,
          city: order.city,
        },
      });

      try {
        const companyOrder = !!order.orgNumber;

        if (companyOrder) {
          await dispatch('setCompanyInputAndValidate', { key: 'orgNumber', value: order.orgNumber });
        }

        const orderCustomerType = companyOrder
          ? CUSTOMER_TYPES.company
          : CUSTOMER_TYPES.consumer;

        await dispatch('setFinancingCustomerType', {
          financingStateKey: orderModelId,
          customerType: orderCustomerType,
          updateConfiguratorKey: false,
        });
      } catch (error) {
        consoleLog(error);
      }

      try {
        const financingOptionType = getters.getAvailableFinancingOptions({
          financingStateKey: orderModelId,
          allowCashClones: true,
        })
          ?.find?.((option) => option.paymentType === order.paymentType)
          ?.type;
        await dispatch('setSelectedFinancingOption', {
          financingStateKey: orderModelId,
          financingOptionType,
          updateConfiguratorKey: false,
        });
      } catch (error) {
        consoleLog(error);
      }

      try {
        const financingSettings = {
          [FINANCING_OPTIONS.leasing]: DEFAULT_SETTINGS_LEASING(),
          [FINANCING_OPTIONS.loan]: DEFAULT_SETTINGS_LOAN(),
        };

        const orderFinancingSettings = {
          [FINANCING_OPTIONS.leasing]: JSON.parse(order.leasingData) ?? {},
          [FINANCING_OPTIONS.loan]: JSON.parse(order.loanData) ?? {},
        };

        const orderKeyOverrides = {
          [LEASING_SETTINGS.advanceRent]: 'advanceRentPercent',
          [LOAN_SETTINGS.downPayment]: 'downPaymentPercent',
          [LOAN_SETTINGS.paymentPeriod]: 'loanPeriod',
        };

        const financingSettingsPromises = [];
        Object.keys(financingSettings).forEach((optionKey) => {
          Object.keys(financingSettings[optionKey]).forEach((settingKey) => {
            const key = orderKeyOverrides[settingKey] ?? settingKey;
            const value = orderFinancingSettings?.[optionKey]?.[key];
            if (value === undefined) return;

            financingSettingsPromises.push(
              dispatch('setFinancingSetting', {
                financingStateKey: orderMarketModelId,
                financingOptionType: optionKey,
                settingKey,
                value,
                updateConfiguratorKey: false,
              }),
            );
          });
        });

        await Promise.all(financingSettingsPromises);
      } catch (error) {
        consoleLog(error);
      }

      try {
        const orderMileageYearPriceSettings = JSON.parse(order?.mileageYearPriceSettings) ?? {};

        const mypPromises = Object.entries(orderMileageYearPriceSettings).map(async ([optionId, mypObj]) => {
          await dispatch('setOptionMileageYearPriceSettings', {
            marketModelId: orderMarketModelId,
            marketModelOptionId: optionId,
            annualMileage: mypObj.annualMileage,
            period: mypObj.period,
            updateConfiguratorKey: false,
          });
        });

        await Promise.all(mypPromises);
      } catch (error) {
        consoleLog(error);
      }

      await dispatch('fetchAndSetSelectionBasedData', { marketModelId: orderMarketModelId });
      await dispatch('setSelectedDealer', order.dealerNumber);
      await dispatch('setConfiguratorKeyParam', { modelId: orderModelId });
    },

    async createOrder({ getters, dispatch }) {
      try {
        const orderData = await dispatch('buildOrderData');

        orderData.createdBy = getters.isDealerLoggedIn
          ? ORDER_INSTIGATOR.salesperson
          : ORDER_INSTIGATOR.customer;

        const debug = getters.showNewCarTaxationDebugData;

        const createOrderUrl = getters.isStockCarConfigurator()
          ? getters.apiGetStockCarCreateOrderUrl(getters.getModelSelectedMarketModelId())
          : getters.apiCreateOrderUrl;

        const orderResponse = await httpInternal(
          {
            method: 'post',
            url: createOrderUrl,
            data: orderData,
            params: {
              debug,
            },
          },
        );

        if (debug) {
          const { paymentData = {} } = orderResponse.data ?? {};
          const paymentDataJson = JSON.stringify(paymentData);
          consoleLog('--------------- DEBUG LIVE CALC START -----------------');
          consoleLog(paymentDataJson);
          consoleLog('--------------- DEBUG LIVE CALC END -----------------');
        }

        await dispatch('setOrderVerificationInProgress', true);
        return orderResponse;
      } catch (error) {
        await dispatch('setOrderVerificationInProgress', false);
        await dispatch('handleOrderError', error);
        throw error;
      }
    },

    async updateOrder({ getters, dispatch }) {
      if (!getters.isChangingOrder()) {
        dispatch('setAlert', { key: 'noOpenedOrderError', message: 'error.noOpenedOrder' });
        throw new Error('No opened order to update.');
      }

      try {
        let orderData = await dispatch('buildOrderData');

        const isDealer = getters.isDealerLoggedIn;
        const addUserData = isDealer;

        orderData = await dispatch('appendOpenedOrderData', { orderData, addUserData });

        if (isDealer) {
          orderData.salespersonName = `${getters.getLoggedInDealer.firstName} ${getters.getLoggedInDealer.lastName}`;
        }

        orderData.updatedBy = isDealer
          ? ORDER_INSTIGATOR.salesperson
          : ORDER_INSTIGATOR.customer;

        return await httpInternal(
          {
            method: 'post',
            url: getters.apiUpdateOrderUrl,
            data: orderData,
          },
        );
      } catch (error) {
        await dispatch('handleOrderError', error);
        throw error;
      }
    },

    async buildOrderData({ getters }) {
      const disclaimer = getters.getMarketModelPriceDisclaimer({
        addAsterisk: false, useDefaultFinancingSettings: false, ignoreDiscount: false,
      }) || '';

      // @todo Shouldn't we only send leasing/loan values if that's the chosen payment method?
      let financingValues = null;

      const financingPaymentType = getters.getFinancingOptionData()?.paymentType;

      const marketModelId = getters.getModelSelectedMarketModelId();

      const cashPrice = getters.getConfigurationTotalCostPerFinancingOption({
        financingOptionType: FINANCING_OPTIONS.cash,
        alwaysIncludeVat: true,
        ignoreDiscount: false,
        discountFinancingOptionType: getters.getSelectedFinancingOption(),
      });

      const financingConfig = getters.getMarketModelFinancingConfig(marketModelId);

      const leasingValues = getters.getConfigurationLeasingValues({
        useDefaultFinancingSettings: false,
        ignoreDiscount: false,
      });

      const loanCashTotal = getters.getConfigurationLoanCashTotal({
        marketModelId,
        includeMarketModel: true,
        includeDelivery: true,
        includeNewCarTaxation: true,
        ignoreDiscount: false,
      });

      const loanValues = getters.getLoanValues({
        financingStateKey: marketModelId,
        cashPrice: loanCashTotal,
        financingConfig,
        includeFees: true,
        useDefaultFinancingSettings: false,
      });

      const isCompany = getters.getFinancingCustomerType() === CUSTOMER_TYPES.company;
      const orgNumber = isCompany ? getters.companyInfo.orgNumber : null;
      const companyName = isCompany ? getters.companyInfo.companyName : null;

      financingValues = {
        leasing: {
          cashPrice,
          advanceRent: leasingValues[LEASING_KEYS.advanceRent],
          advanceRentPercent: leasingValues[LEASING_KEYS.advanceRentPercent],
          annualMileage: leasingValues[LEASING_KEYS.annualMileage],
          establishmentFee: leasingValues[LEASING_KEYS.establishmentFee],
          nominalInterestRate: leasingValues[LEASING_KEYS.nominalInterestRate],
          leasePeriod: leasingValues[LEASING_KEYS.leasePeriod],
          monthlyInstallment: leasingValues[LEASING_KEYS.monthlyInstallment],
          perTermFee: leasingValues[LEASING_KEYS.perTermFee],
          totalCost: leasingValues[LEASING_KEYS.totalCost],
          residualValueTotal: leasingValues[LEASING_KEYS.residualValueTotal],
          // @todo Add new electric vat related shit
        },
        loan: {
          cashPrice: loanCashTotal,
          downPayment: loanValues[LOAN_KEYS.downPayment],
          downPaymentPercent: loanValues[LOAN_KEYS.downPaymentPercent],
          establishmentFee: loanValues[LOAN_KEYS.establishmentFee],
          effectiveInterestRate: loanValues[LOAN_KEYS.effectiveInterestRate],
          nominalInterestRate: loanValues[LOAN_KEYS.nominalInterestRate],
          loanAmount: loanValues[LOAN_KEYS.loanAmount],
          loanNeeds: loanValues[LOAN_KEYS.loanNeeds],
          loanPeriod: loanValues[LOAN_KEYS.loanPeriod],
          monthlyInstallment: loanValues[LOAN_KEYS.monthlyInstallment],
          perTermFee: loanValues[LOAN_KEYS.perTermFee],
          propertyRegistrationFee: loanValues[LOAN_KEYS.propertyRegistrationFee],
          totalLoanCost: loanValues[LOAN_KEYS.totalLoanCost],
          totalCost: loanValues[LOAN_KEYS.totalCost],
        },
        mileageYearPriceSettings: getters.getMileageYearPriceStateSettings(marketModelId),
      };

      const { modelCode, modelYear } = getters.getModelById();

      const shareConfigUrl = await getShareConfigUrl();

      const orderMarketModelId = getters.isStockCarConfigurator()
        ? getters.getMarketModelById()?.baseMarketModelId
        : marketModelId;

      return {
        dealerNumber: getters.selectedDealer,
        deliveryCost: getters.getMarketModelDeliveryCost({
          financingOptionType: FINANCING_OPTIONS.cash,
        }),
        modelCode,
        modelYear,
        marketModelId: orderMarketModelId,
        marketModelPrice: getters.getMarketModelPrice({
          financingOptionType: FINANCING_OPTIONS.cash,
        }),
        mainType: getters.getMarketModelById()?.mainType,
        categories: JSON.stringify(getters.getMarketModelCategories()),
        groups: JSON.stringify(getters.getMarketModelGroups()),
        selections: JSON.stringify(getters.getMarketModelSelectionsData({ skipBundled: false })),
        userAlreadyRegistered: getters.isExistingUser,
        userUuid: getters.userInfo.userUuid,
        partyId: getters.userInfo.partyId,
        mobileNumber: getters.userInfo.mobileNumber,
        phoneCountryCode: getters.userInfo.phoneCountryCode,
        email: getters.userInfo.email,
        firstName: getters.userInfo.firstName,
        lastName: getters.userInfo.lastName,
        addressLine: getters.userInfo.address,
        zipCode: getters.userInfo.zipCode,
        city: getters.userInfo.city,
        countryCode: getters.userInfo.countryCode,
        totalCashPrice: cashPrice,
        disclaimer,
        financingPaymentType,
        financingValues,
        orgNumber,
        companyName,
        createdBy: null,
        updatedBy: null,
        salespersonName: null,
        customerComment: null,
        shareConfigUrl,
        newCarTaxation: {
          weightBasedTax: getters.getConfigurationWeightBasedTax({ marketModelId }),
          electricVatFreeAmount: getters.getConfigurationElectricVatFreeAmount,
          electricVatBasis: getters.getConfigurationElectricVatBasis({ marketModelId }),
          electricVatBasisAfterFreeAmount: getters.getConfigurationElectricVatBasisAfterFreeAmount({ marketModelId }),
          electricVatTotal: getters.getConfigurationElectricVatTotal({ marketModelId }),
          electricVatTotalUndiscounted: getters.getConfigurationElectricVatTotal({ marketModelId, ignoreDiscount: true }),
          electricProportionateVatRate: getters.getConfigurationElectricVatProportionateVatRate({ marketModelId }),
        },
        wltp: formatOrderWltpData(getters.getWltpValues({ marketModelId })),
        tradeInVehicle: {
          registrationNumber: getters.hasTradeInVehicle ? getters.userInfo.carRegistrationNumber : null,
        },
      };
    },

    async appendOpenedOrderData({ getters }, {
      orderData = {},
      addUserData = false,
    } = {}) {
      if (!getters.isChangingOrder()) return orderData;

      let keysToAdd = [
        'orderId',
        'createdBy',
        'salespersonName',
        'numberOfCars',
        'numberOfReservations',
        'customerComment',
      ];

      if (addUserData) {
        keysToAdd = [
          ...keysToAdd,
          ...[
            'userUuid',
            'firstName',
            'lastName',
            'mobileNumber',
            'email',
            'addressLine',
            'zipCode',
            'city',
            'orgNumber',
            'companyName',
          ],
        ];
      }

      const openedOrder = getters.getOpenedOrderState();

      keysToAdd.forEach((key) => {
        // eslint-disable-next-line no-param-reassign
        orderData[key] = openedOrder[key];
      });

      return orderData;
    },

    handleOrderError({ getters, dispatch }, error = {}) {
      const { response: errorResponse = {} } = error;
      const { exception = '' } = errorResponse.data ?? {};
      const { status = 500 } = errorResponse;
      let message = getters.isChangingOrder()
        ? 'error.updateOrder'
        : 'error.createOrder';

      // @todo Errors no longer return exception, this needs to be refactored for new Error format
      const { reason = '' } = errorResponse.data ?? {};

      switch (status) {
        case 400:
          switch (exception) {
            case 'InvalidArgumentException':
              message = 'error.invalidOrderData';
              break;
            case 'ValidationException':
              message = 'error.validation';
              break;
            default:
              break;
          }
          break;
        case 403:
          switch (reason) {
            case STOCK_CARS_ORDER_ERROR_REASONS.pending:
              message = 'error.stockCars.createOrder.pending';
              break;
            case STOCK_CARS_ORDER_ERROR_REASONS.purchased:
              message = 'error.stockCars.createOrder.purchased';
              break;
            default:
              break;
          }
          break;
          // switch (exception) {
          //   case 'PreReservationNotEligibleException':
          //     message = 'error.preReservationNotEligible';
          //     break;
          //   case 'PreReservationAlreadyUsedException':
          //     message = 'error.preReservationAlreadyUsed';
          //     break;
          //   case 'OutOfStockException':
          //     message = 'error.outOfStock';
          //     break;
          //   default:
          //     break;
          // }
        case 404:
          switch (exception) {
            case 'NotFoundException':
              message = 'error.marketModelNotFound';
              break;
            case 'InvalidPreReservationException':
              message = 'error.invalidPreReservation';
              break;
            default:
              break;
          }
          break;
        case 424:
          switch (exception) {
            case 'GetPreReservationsException':
              message = 'error.getPreReservation';
              break;
            case 'CreateOrderException':
              message = 'error.createOrder';
              break;
            case 'UpdateOrderException':
              message = 'error.updateOrder';
              break;
            default:
              break;
          }
          break;
        case 500:
          switch (exception) {
            case 'PimcoreWriteException':
              message = 'error.systemError';
              break;
            case 'InvalidMarketModelException':
              message = 'error.systemError';
              break;
            case 'MissingConfigException':
              message = 'error.systemError';
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }

      dispatch('setAlert', {
        key: 'createOrderError',
        message,
        log: error,
      });
    },

    async validateOrder({ getters }, { uniqueId = null, confirmScrive = false }) {
      const validateOrderUrl = getters.isStockCarConfigurator()
        ? getters.apiGetStockCarValidateOrderUrl(uniqueId)
        : getters.apiValidateOrderUrl(uniqueId);

      const response = await httpInternal(
        {
          method: 'get',
          url: validateOrderUrl,
          params: {
            confirmScrive,
          },
        },
      );
      if (!response?.data?.status) {
        consoleLog(`No order status from validateOrder: ${response.data.msg}`);
        throw new Error('No order status');
      }
      return response;
    },

    async setOpenedOrderNumberOfCars({ getters, commit }, {
      openedOrderStateId = getters.getActiveModelId,
      numberOfCars = 1,
    } = {}) {
      const { numberOfReservations } = getters.getOpenedOrderState(openedOrderStateId);

      let reservations = roundNumber(numberOfReservations);
      reservations = reservations > 0 ? reservations : 1;

      let cars = roundNumber(numberOfCars);
      cars = cars > 0 ? cars : 1;
      cars = cars <= reservations ? cars : reservations;

      commit('UPDATE_OPENED_ORDER_NUMBER_OF_CARS', { openedOrderStateId, numberOfCars: cars });
    },

    async validateOrderNumberOfCars({ dispatch }, { setError = false }) {
      return dispatch('validate', {
        key: 'orderNumberOfCars', setError,
      });
    },

    async setOpenedOrderCustomerComment({ getters, commit }, {
      openedOrderStateId = getters.getActiveModelId,
      customerComment = '',
    } = {}) {
      commit('UPDATE_OPENED_ORDER_CUSTOMER_COMMENT', { openedOrderStateId, customerComment });
    },

    async setOpenedOrderInformationShown({ getters, commit }, {
      openedOrderStateId = getters.getActiveModelId,
      orderInformationShown = false,
    } = {}) {
      commit('UPDATE_OPENED_ORDER_INFORMATION_SHOWN', { openedOrderStateId, orderInformationShown });
    },

    async setOrderVerificationInProgress({ commit }, verificationInProgress = false) {
      commit('UPDATE_VERIFICATION_IN_PROGRESS', verificationInProgress);
    },

  },
};
export default OrdersStore;
