const CommonApiStore = {
  state: {
    // Internal API
    apiHost: process.env.apiHost,
    apiEndpoints: {
      cms: {
        getPage: 'api/cms/getPage?path=/',
        getSnippet: 'api/cms/getSnippet?path=/',
      },
      checkout: {
        reservationOpen: 'api/checkout/reservationOpen',
        allowedToReserve: 'api/checkout/allowedToReserve',
      },
      configurator: {
        allowedToConfigure: 'api/configurator/allowedToConfigure',
      },
      creditCheck: {
        decision: 'api/creditcheck/decision',
      },
      dealers: {
        authenticateDealer: 'api/dealers/authenticateDealer',
        getDealers: 'api/dealers/getDealers',
        getPreviousDealersFromPhone: 'api/dealers/getPreviousDealersFromPhone',
        getSuggestedDealersFromZip: 'api/dealers/getSuggestedDealersFromZip',
      },
      eSim: {
        createESimRequest: 'api/esim/createESimRequest',
      },
      fleet: {
        createFleetReservation: 'api/fleet/createFleetReservation',
      },
      stockCars: {
        getInventory: 'api/inventory/getInventory',
        getStockCars: 'api/stockCars',
        getStockCarById: (id) => `api/stockCars/${id}`,
        getStockCarOptions: (id) => `api/stockCars/${id}/options`,
        createStockCarOrder: (id) => `api/stockCars/${id}/order`,
        validateStockCarOrder: (orderId) => `api/stockCars/validateOrder/${orderId}`,
      },
      nets: {
        register: 'api/nets/register',
        process: 'api/nets/process',
        cancel: 'api/nets/cancel',
      },
      orders: {
        createOrder: 'api/orders/createOrder',
        updateOrder: 'api/orders/updateOrder',
        getOrder: 'api/orders/getOrder',
        getEditableOrders: 'api/orders/getEditableOrders',
        validateOrder: 'api/orders/validateOrder/',
      },
      products: {
        getReservationProduct: 'api/products/getReservationProduct/',
      },
      scrive: {
        process: 'api/scrive/process',
      },
      settings: {
        getConfig: 'api/settings/getConfig',
        getTranslations: 'api/settings/getTranslations',
        checkModule: 'api/settings/checkModule',
      },
      system: {
        getContentVersion: 'api/system/getContentVersion',
        getAnnouncements: 'api/system/getAnnouncements',
      },
      tradeIn: {
        createTradeInRequest: 'api/tradeIn/createTradeInRequest',
      },
      user: {
        getPrimaryPreReservation: 'api/user/getPrimaryPreReservation',
      },
      vehicles: {
        getModels: 'api/vehicles/getModels',
        getModelByUrlKey: 'api/vehicles/getModelByUrlKey',
        getMarketModels: 'api/vehicles/getMarketModels',
        getMarketModelById: 'api/vehicles/getMarketModelById',
        getMarketModelDeliveryData: 'api/vehicles/getMarketModelDeliveryData',
        getMarketModelDynamicImages: 'api/vehicles/getMarketModelDynamicImages',
        getMarketModelOptions: 'api/vehicles/getMarketModelOptions',
      },
      wltp: {
        getWltpData: 'api/vehicles/getMarketModelWltp',
      },
    },
  },
  getters: {
    // Checkout
    apiProductReservationOpenUrl: (state) => `${state.apiHost}${state.apiEndpoints.checkout.reservationOpen}`,
    apiAllowedToReserveUrl: (state) => `${state.apiHost}${state.apiEndpoints.checkout.allowedToReserve}`,
    // Cms
    apiGetPageUrl: (state, getters) => `${state.apiHost}${state.apiEndpoints.cms.getPage}${getters.appScope}`,
    apiGetSnippetUrl: (state, getters) => (addRootPath = true) => {
      const rootPath = addRootPath ? `${getters.appScope}/snippets/` : '';
      return `${state.apiHost}${state.apiEndpoints.cms.getSnippet}${rootPath}`;
    },
    // Configurator
    apiAllowedToConfigureUrl: (state) => `${state.apiHost}${state.apiEndpoints.configurator.allowedToConfigure}`,
    // CreditCheck
    apiCreditCheckDecisionUrl: (state) => `${state.apiHost}${state.apiEndpoints.creditCheck.decision}`,
    // Dealers
    apiGetAuthenticateDealerUrl: (state) => `${state.apiHost}${state.apiEndpoints.dealers.authenticateDealer}`,
    apiGetDealersUrl: (state) => `${state.apiHost}${state.apiEndpoints.dealers.getDealers}`,
    apiGetSuggestedDealersFromZipUrl: (state) => `${state.apiHost}${state.apiEndpoints.dealers.getSuggestedDealersFromZip}`,
    apiGetPreviousDealersFromPhoneUrl: (state) => `${state.apiHost}${state.apiEndpoints.dealers.getPreviousDealersFromPhone}`,
    // ESim
    apiCreateESimRequestUrl: (state) => `${state.apiHost}${state.apiEndpoints.eSim.createESimRequest}`,
    // Fleet
    apiCreateFleetReservation: (state) => `${state.apiHost}${state.apiEndpoints.fleet.createFleetReservation}`,
    // Stock Cars
    apiGetInventoryUrl: (state) => `${state.apiHost}${state.apiEndpoints.stockCars.getInventory}`,
    apiGetStockCarsUrl: (state) => `${state.apiHost}${state.apiEndpoints.stockCars.getStockCars}`,
    apiGetStockCarByIdUrl: (state) => (id) => `${state.apiHost}${state.apiEndpoints.stockCars.getStockCarById(id)}`,
    apiGetStockCarOptionsUrl: (state) => (id) => `${state.apiHost}${state.apiEndpoints.stockCars.getStockCarOptions(id)}`,
    apiGetStockCarCreateOrderUrl: (state) => (id) => `${state.apiHost}${state.apiEndpoints.stockCars.createStockCarOrder(id)}`,
    apiGetStockCarValidateOrderUrl: (state) => (orderId) => `${state.apiHost}${state.apiEndpoints.stockCars.validateStockCarOrder(orderId)}`,
    // Nets
    apiNetsRegisterUrl: (state) => `${state.apiHost}${state.apiEndpoints.nets.register}`,
    apiNetsProcessUrl: (state) => `${state.apiHost}${state.apiEndpoints.nets.process}`,
    apiNetsCancelUrl: (state) => `${state.apiHost}${state.apiEndpoints.nets.cancel}`,
    // Order
    apiCreateOrderUrl: (state) => `${state.apiHost}${state.apiEndpoints.orders.createOrder}`,
    apiUpdateOrderUrl: (state) => `${state.apiHost}${state.apiEndpoints.orders.updateOrder}`,
    apiValidateOrderUrl: (state) => (uniqueId) => `${state.apiHost}${state.apiEndpoints.orders.validateOrder}${uniqueId}`,
    apiGetOrderUrl: (state) => `${state.apiHost}${state.apiEndpoints.orders.getOrder}`,
    apiGetEditableOrdersUrl: (state) => `${state.apiHost}${state.apiEndpoints.orders.getEditableOrders}`,
    // Products
    apiGetReservationProductUrl: (state) => (sku) => `${state.apiHost}${state.apiEndpoints.products.getReservationProduct}${sku}`,
    // Scrive
    apiScriveProcessUrl: (state) => `${state.apiHost}${state.apiEndpoints.scrive.process}`,
    // Settings
    apiGetConfigUrl: (state) => `${state.apiHost}${state.apiEndpoints.settings.getConfig}`,
    apiGetTranslationsUrl: (state) => `${state.apiHost}${state.apiEndpoints.settings.getTranslations}`,
    apiCheckModuleUrl: (state) => `${state.apiHost}${state.apiEndpoints.settings.checkModule}`,
    // System
    apiGetContentVersionUrl: (state) => `${state.apiHost}${state.apiEndpoints.system.getContentVersion}`,
    apiGetAnnouncementsUrl: (state) => `${state.apiHost}${state.apiEndpoints.system.getAnnouncements}`,
    // TradeIn
    apiCreateTradeInRequestUrl: (state) => `${state.apiHost}${state.apiEndpoints.tradeIn.createTradeInRequest}`,
    // User
    apiGetPrimaryPreReservationUrl: (state) => `${state.apiHost}${state.apiEndpoints.user.getPrimaryPreReservation}`,
    // Vehicles
    apiGetModelsUrl: (state) => `${state.apiHost}${state.apiEndpoints.vehicles.getModels}`,
    apiGetModelByUrlKeyUrl: (state) => `${state.apiHost}${state.apiEndpoints.vehicles.getModelByUrlKey}`,
    apiGetMarketModelsUrl: (state) => `${state.apiHost}${state.apiEndpoints.vehicles.getMarketModels}`,
    apiGetMarketModelByIdUrl: (state) => `${state.apiHost}${state.apiEndpoints.vehicles.getMarketModelById}`,
    apiGetMarketModelDeliveryDataUrl: (state) => `${state.apiHost}${state.apiEndpoints.vehicles.getMarketModelDeliveryData}`,
    apiGetMarketModelDynamicImagesUrl: (state) => `${state.apiHost}${state.apiEndpoints.vehicles.getMarketModelDynamicImages}`,
    apiGetMarketModelOptionsUrl: (state) => `${state.apiHost}${state.apiEndpoints.vehicles.getMarketModelOptions}`,
    // WLTP
    apiGetWltpDataUrl: (state) => `${state.apiHost}${state.apiEndpoints.wltp.getWltpData}`,
  },
};

export default CommonApiStore;
