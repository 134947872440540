// Usage: sessionStorage.setItem('debug', true)
const consoleLog = (message, data) => {
  const debugParam = sessionStorage.getItem('debug');

  if (debugParam === 'false') {
    return;
  }

  const showConsoleLog = debugParam === 'true' || process.env.NODE_ENV === 'development';

  if (showConsoleLog && message !== undefined) {
    // eslint-disable-next-line no-console
    console.log(message, data);
  }
};

export default consoleLog;
