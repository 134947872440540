import toNumber from 'lodash/toNumber';
import i18n from '@/setup/i18n-init';
import { LOAN_KEYS, LEASING_KEYS } from '@/constants/constants-financing';
import { roundNumber } from '@/assets/js/helpers/general-helpers';
import consoleLog from '@/assets/js/helpers/console-log';

function generateNextInterestIncrement(xLast, loanAmount, monthlyInstalment, monthlyPayments) {
  const fx = loanAmount * (1 - xLast ** -1) - monthlyInstalment * (xLast ** monthlyPayments - 1);
  // eslint-disable-next-line max-len
  const fxp = loanAmount * xLast ** (-1 - 1) - monthlyPayments * monthlyInstalment * xLast ** (monthlyPayments - 1);
  return xLast - fx / fxp;
}

function getInterestOfX(x) {
  return x ** (-1 * 12) - 1;
}

export const VAT_CORRECTION_FACTORS_PER_YEAR = {
  1: 0.6,
  2: 0.4,
  3: 0.2,
  4: 0,
  5: 0,
  6: 0,
  7: 0,
  8: 0,
};

export function getVATRate(vatRatePercent) {
  return 1 + vatRatePercent / 100;
}

export function deductVAT(cashPrice = 0, vatRatePercent = 0) {
  return cashPrice / getVATRate(vatRatePercent);
}

export function excelRate({
  nper = 0,
  pmt = 0,
  pv = 0,
  fv = 0,
  type = 0,
  guess = 0.1,
} = {}) {
  // Set maximum epsilon for end of iteration
  const epsMax = 1e-10;

  // Set maximum number of iterations
  const iterMax = 20;

  // Implement Newton's method
  let y;
  let y0;
  let y1;
  let x0;
  let x1 = 0;
  let f = 0;
  let i = 0;
  let rate = guess;
  if (Math.abs(rate) < epsMax) {
    y = pv * (1 + nper * rate) + pmt * (1 + rate * type) * nper + fv;
  } else {
    f = Math.exp(nper * Math.log(1 + rate));
    y = pv * f + pmt * (1 / rate + type) * (f - 1) + fv;
  }
  y0 = pv + pmt * nper + fv;
  y1 = pv * f + pmt * (1 / rate + type) * (f - 1) + fv;
  // eslint-disable-next-line no-multi-assign
  i = x0 = 0;
  x1 = rate;
  while ((Math.abs(y0 - y1) > epsMax) && (i < iterMax)) {
    rate = (y1 * x0 - y0 * x1) / (y1 - y0);
    x0 = x1;
    x1 = rate;
    if (Math.abs(rate) < epsMax) {
      y = pv * (1 + nper * rate) + pmt * (1 + rate * type) * nper + fv;
    } else {
      f = Math.exp(nper * Math.log(1 + rate));
      y = pv * f + pmt * (1 / rate + type) * (f - 1) + fv;
    }
    y0 = y1;
    y1 = y;
    // eslint-disable-next-line no-plusplus
    ++i;
  }
  return rate;
}

export function excelPmt({
  ratePerPeriod = 0.00,
  numberOfPayments = 0,
  presentValue = 0,
  futureValue = 0,
  type = 0,
} = {}) {
  if (ratePerPeriod !== 0.00) {
    const q = (1 + ratePerPeriod) ** numberOfPayments;
    return -(ratePerPeriod * (futureValue + (q * presentValue)))
      / ((-1 + q) * (1 + ratePerPeriod * (type)));
  }

  if (numberOfPayments > 0) {
    return -(futureValue + presentValue) / numberOfPayments;
  }

  return 0;
}

export function calculateEffectiveRate({
  paymentPeriodYears = 0,
  termsPerYear = 12,
  monthlyInstallment = 0,
  loanAmount = 0,
  interestGuess = 0.1,
} = {}) {
  const payments = paymentPeriodYears * termsPerYear;

  const effectiveRate = ((excelRate({
    nper: payments, pmt: -monthlyInstallment, pv: loanAmount, guess: interestGuess,
  }) + 1) ** termsPerYear) - 1;

  return roundNumber(effectiveRate * 100, 2);
}

export function calculateEffectiveRateAnnuity(
  loanAmount,
  interestGuess,
  monthlyInstalment,
  monthlyPayments,
) {
  let tooMany = 0;
  let xLast;
  let xNext;
  let guess = interestGuess;

  xLast = (1 + guess) ** (-1.0 / 12);

  // eslint-disable-next-line no-plusplus
  while (tooMany++ < 1000) {
    xNext = generateNextInterestIncrement(xLast, loanAmount, monthlyInstalment, monthlyPayments);

    const interestLast = getInterestOfX(xLast);
    const interestNext = getInterestOfX(xNext);
    // if the difference is small enough
    if (interestLast < 0) {
      guess += 0.001;
      xNext = (1 + guess) ** (-1.0 / 12);
    } else if (Math.abs(interestLast - interestNext) <= 1.0 / 10 ** 5) {
      return roundNumber(interestNext * 100, 2);
    }
    xLast = xNext;
  }
  return 0;
}

export function calculateLeasingVatCorrection({
  cashPrice = 0,
  leasePeriodYears = 0,
  vatRatePercent = 0,
  taxes = 0,
} = {}) {
  if (vatRatePercent === 0) return 0;

  const correctionFactor = VAT_CORRECTION_FACTORS_PER_YEAR[leasePeriodYears];
  const vatRate = getVATRate(vatRatePercent);
  const vatBasedPrice = cashPrice - taxes;

  return roundNumber(
    ((vatBasedPrice - (deductVAT(vatBasedPrice, vatRatePercent))) * correctionFactor) / (leasePeriodYears * 12),
  )
    * vatRate;
}

export function calculateLeasingMonthlyCost({
  cashPrice = 0,
  advanceRentPercent = 0,
  nominalInterestRate = 0.00,
  leasePeriodYears = 0,
  // residualValuePercent = 0.00,
  taxes = 0,
  residualValue = 0.00,
  perTermFee = 0,
  vatRatePercent = 0,
  useVatCorrection = false,
} = {}) {
  const ratePerPeriod = nominalInterestRate / 12 / 100;
  const vatRate = getVATRate(vatRatePercent);
  const advanceRentRate = advanceRentPercent / 100;
  // const residualValueRate = residualValuePercent / 100;
  const numberOfPayments = leasePeriodYears * 12;

  const pv = -(((cashPrice - taxes) / vatRate + taxes)
    - (cashPrice / vatRate) * advanceRentRate);
  // const fv = cashPrice * residualValueRate;

  const leasingPmt = excelPmt({
    ratePerPeriod,
    numberOfPayments,
    presentValue: pv,
    // futureValue: fv,
    futureValue: residualValue,
    type: 1,
  });

  const monthlyInstallment = leasingPmt * vatRate;

  const vatCorrection = useVatCorrection
    ? calculateLeasingVatCorrection({
      cashPrice, leasePeriodYears, vatRatePercent, taxes,
    })
    : 0;

  // @todo ELECTRIC VAT CORRECTION 2024 Keep until verified
  if (cashPrice === 690000) {
    consoleLog('---------- calculateLeasingMonthlyCost START -----------');
    consoleLog('CASH PRICE', cashPrice);
    consoleLog('RESIDUAL VALUE', residualValue);
    consoleLog('ADVANCE RENT PERCENT', advanceRentPercent);
    consoleLog('TAXES', taxes);
    consoleLog('INTEREST RATE', nominalInterestRate);
    consoleLog('VAT CORRECTION', vatCorrection);
    consoleLog('PER TERM FEE', perTermFee);
    consoleLog('MONTHLY INSTALLMENT', toNumber(monthlyInstallment + vatCorrection + perTermFee));
    consoleLog('---------- calculateLeasingMonthlyCost END -----------');
  }

  return toNumber(monthlyInstallment + vatCorrection + perTermFee);
}

export function calculateLeasingAnnualServiceMonthlyCost({
  cashPrice = 0,
  nominalInterestRate = 0.00,
  leasePeriodYears = 0,
  vatRatePercent = 0,
} = {}) {
  const ratePerPeriod = nominalInterestRate / 12 / 100;
  const cashPriceExVat = deductVAT(cashPrice, vatRatePercent);

  const pv = -(cashPriceExVat / leasePeriodYears);

  const annualServicePmt = excelPmt({
    ratePerPeriod,
    numberOfPayments: 12,
    presentValue: pv,
    futureValue: -1,
    type: 1,
  });

  return roundNumber(annualServicePmt * getVATRate(vatRatePercent));
}

export function calculateLoanMonthlyCost({
  cashPrice = 0,
  downPaymentPercent = 0,
  nominalInterestRate = 0.00,
  paymentPeriodYears = 0,
  establishmentFee = 0,
  // Property Registration Fee (Tinglysningsgebyr) should not be part of the calculation.
  // UPDATE 2025-03-17 Property Registration Fee should once again BE part of the calculation according to Wiebke and MNet.
  propertyRegistrationFee = 0,
  perTermFee = 0,
} = {}) {
  const ratePerPeriod = nominalInterestRate / 12 / 100;
  const numberOfPayments = paymentPeriodYears * 12;

  // const fees = establishmentFee;
  const fees = establishmentFee + propertyRegistrationFee;

  const downPayment = cashPrice * (downPaymentPercent / 100);
  const presentValue = -(cashPrice - downPayment + fees);

  const monthlyLoanCost = excelPmt({
    ratePerPeriod,
    numberOfPayments,
    presentValue,
  });

  return roundNumber(monthlyLoanCost + perTermFee);
}

export function getLeasingKeyLabel(loanKey = '') {
  const translations = {
    [LEASING_KEYS.annualMileage]: 'financing.leasing.keyLabel.annualMileage',
    [LEASING_KEYS.advanceRent]: 'financing.leasing.keyLabel.advanceRent',
    [LEASING_KEYS.leasePeriod]: 'financing.leasing.keyLabel.leasePeriod',
    [LEASING_KEYS.establishmentFee]: 'financing.leasing.keyLabel.establishmentFee',
  };

  return i18n.tc(translations[loanKey]);
}

export function getLoanKeyLabel(loanKey = '') {
  const translations = {
    [LOAN_KEYS.downPayment]: 'financing.loan.keyLabel.downPayment',
    [LOAN_KEYS.loanPeriod]: 'financing.loan.keyLabel.loanPeriod',
    [LOAN_KEYS.loanNeeds]: 'financing.loan.keyLabel.loanNeeds',
    [LOAN_KEYS.loanAmount]: 'financing.loan.keyLabel.loanAmount',
    [LOAN_KEYS.effectiveInterestRate]: 'financing.loan.keyLabel.effectiveInterestRate',
    [LOAN_KEYS.nominalInterestRate]: 'financing.loan.keyLabel.nominalInterestRate',
    [LOAN_KEYS.establishmentFee]: 'financing.loan.keyLabel.establishmentFee',
    [LOAN_KEYS.propertyRegistrationFee]: 'financing.loan.keyLabel.propertyRegistrationFee',
    [LOAN_KEYS.totalLoanCost]: 'financing.loan.keyLabel.totalLoanCost',
    [LOAN_KEYS.totalCost]: 'financing.loan.keyLabel.totalCost',
  };

  return i18n.tc(translations[loanKey]);
}

export function createLeasingDataParams({
  items = [],
  advanceRentPercent = 0,
  nominalInterestRate = 0.00,
  leasePeriodYears = 0,
  annualMileage = 0,
  establishmentFee = 0,
  perTermFee = 0,
  electricProportionateVatRate = 0.00,
  useVatCorrection = false,
} = {}) {
  return {
    items,
    advanceRentPercent,
    nominalInterestRate,
    leasePeriodYears,
    annualMileage,
    establishmentFee,
    perTermFee,
    electricProportionateVatRate,
    useVatCorrection,
  };
}

export function createLeasingItemData({
  cashPrice = 0,
  residualValuePercent = 0.00,
  vatRatePercent = 0.00,
  taxes = 0,
  discountedValue = 0,
  calculateResidualValueWithDiscountedPrice = false,
  includeFees = false,
  isAnnualService = false,
  isElectricVat = false,
}) {
  return {
    cashPrice,
    residualValuePercent,
    vatRatePercent,
    taxes,
    discountedValue,
    calculateResidualValueWithDiscountedPrice,
    includeFees,
    isAnnualService,
    isElectricVat,
  };
}

export function calculateWeightBasedTax({
  limit = 0,
  costPerKilo = 0,
  weight = 0,
} = {}) {
  if (weight <= limit) return 0;

  const taxableWeight = weight - limit;

  return taxableWeight * costPerKilo;
}
