import round from 'lodash/round';
import ceil from 'lodash/ceil';
import Velocity from 'velocity-animate';
import scrollLock from 'scroll-lock';
import ResizeSensor from 'css-element-queries/src/ResizeSensor';
import isString from 'lodash/isString';
import isFunction from 'lodash/isFunction';
import isElement from 'lodash/isElement';
import consoleLog from '@/assets/js/helpers/console-log';
import i18n from '@/setup/i18n-init';
import { ROUTES } from '@/constants/constants-routes';
import { getClientRouter } from '@/assets/js/helpers/config-helpers';
import { ROUTER_METHODS } from '@/constants/constants-router';

let PRE_FULLSCREEN_SCROLL_POSITION = 0;

// Number functions
export function roundNumber(number, precision = 0) {
  const roundedNumber = round(number, precision);
  return Number.isNaN(roundedNumber) ? 0 : roundedNumber;
}

export function ceilNumber(number, precision = 0) {
  const roundedNumber = ceil(number, precision);
  return Number.isNaN(roundedNumber) ? 0 : roundedNumber;
}

export function formatNumber(number) {
  if (Number.isNaN(number) || number == null) return 0;

  const numString = number.toString();
  const parts = numString.split('.');
  let part1 = parts[0];
  const part2 = parts.length > 1 ? `.${parts[1]}` : '';
  const rgx = /(\d+)(\d{3})/;

  while (rgx.test(part1)) {
    part1 = part1.replace(rgx, '$1 $2');
  }
  return part1 + part2;
}

export function formatPhone(phone) {
  if (!phone) return '';
  const string = phone.toString();
  return string.replace(/^(.{3})(.{2})(.*)$/, '$1 $2 $3');
}

// Resize Sensor
export function createResizeSensor(element, callback) {
  if (!element || !isFunction(callback)) return undefined;

  return new ResizeSensor(element, callback);
}

// Intersection observer
export function createIntersectionObserver(element, callback, options = {}) {
  if (!(element instanceof HTMLElement) || !(typeof callback === 'function')) return undefined;

  const observerOptions = {
    root: null,
    rootMargin: '0px',
    threshold: 0.5,
    ...options,
  };

  const observer = new IntersectionObserver(callback, observerOptions);
  observer.observe(element);

  return observer;
}

export function buildIntersectionThresholdList(steps = 10) {
  const thresholds = [];

  for (let i = 1.0; i <= steps; i += 1) {
    const ratio = i / steps;
    thresholds.push(ratio);
  }

  thresholds.push(0);
  return thresholds;
}

export function elementsOverlap(el1, el2) {
  if (!(el1 instanceof HTMLElement) || !(el2 instanceof HTMLElement)) return false;

  const domRect1 = el1.getBoundingClientRect();
  const domRect2 = el2.getBoundingClientRect();

  return !(
    domRect1.top > domRect2.bottom
    || domRect1.right < domRect2.left
    || domRect1.bottom < domRect2.top
    || domRect1.left > domRect2.right
  );
}

// Scroll functions
export const bodyScroll = {
  disable() {
    const scrollPos = window.scrollY;
    const stickyElements = document.querySelectorAll('.can-stick');

    stickyElements.forEach((el) => {
      if (getComputedStyle(el).position === 'fixed') {
        el.setAttribute('data-scroll-lock-fill-gap', '');
      }
    });

    scrollLock.disablePageScroll();
    window.scrollTo(0, scrollPos);
  },
  enable() {
    const stickyElements = document.querySelectorAll('.can-stick');
    scrollLock.enablePageScroll();

    stickyElements.forEach((el) => {
      el.removeAttribute('data-scroll-lock-fill-gap');
    });
  },
};

function scrollToPromise(scrollTo) {
  const scrollTarget = roundNumber(scrollTo);

  if (roundNumber(scrollTo) === roundNumber(window.scrollY.toFixed())) return Promise.resolve();

  bodyScroll.disable();

  window.scrollTo({
    top: scrollTarget,
    behavior: 'smooth',
  });

  return new Promise((resolve) => {
    let resolved = false;

    const resolveScroll = (method) => {
      window.removeEventListener('scroll', method);
      resolved = true;
      resolve();
      bodyScroll.enable();
    };

    const scrollHandler = () => {
      const currentScrollPos = roundNumber(window.scrollY.toFixed());

      if (currentScrollPos === scrollTarget) {
        resolveScroll(scrollHandler);
      }
    };

    setTimeout(() => {
      if (!resolved) {
        resolveScroll(scrollHandler);
      }
    }, 1000);

    window.addEventListener('scroll', scrollHandler);
  });
}

export function scrollToTop() {
  const scrollTo = 0;

  return scrollToPromise(scrollTo);
}

export function scrollToTopOfElement(el, offset = 0) {
  if (!(el instanceof HTMLElement)) return Promise.resolve();

  const rect = el.getBoundingClientRect();
  const scrollOffset = (rect.top + window.scrollY + offset).toFixed();

  return scrollToPromise(scrollOffset);
}

export function scrollBeneathElement(el) {
  if (!(el instanceof HTMLElement)) return;

  Velocity(document.body, 'stop');

  Velocity(document.body, 'scroll', {
    offset: el.offsetTop + el.offsetHeight,
    duration: 300,
    mobileHA: false,
  });
}

export function scrollToBottomOfElement(id) {
  const el = document.getElementById(id);

  if (el) {
    const rect = el.getBoundingClientRect();
    const elementHeight = el.offsetHeight;
    const windowHeight = window.innerHeight;

    Velocity(document.body, 'scroll', {
      offset: (rect.top + document.documentElement.scrollTop + elementHeight) - windowHeight,
      duration: 300,
      mobileHA: false,
    });
  }
}

function onFullscreenChange() {
  setTimeout(() => {
    window.scrollTo(0, PRE_FULLSCREEN_SCROLL_POSITION);
  }, 0);

  if (!document.fullscreenElement) {
    document.removeEventListener('fullscreenchange', onFullscreenChange);
  }
}

export function toggleFullscreen(el) {
  if (document.fullscreenElement) {
    document.exitFullscreen()
      .catch((error) => consoleLog(error));
  } else {
    if (!el?.requestFullscreen) return;

    PRE_FULLSCREEN_SCROLL_POSITION = window.pageYOffset;
    el.requestFullscreen();
    document.addEventListener('fullscreenchange', onFullscreenChange);
  }
}

export function getElementHeight(el) {
  if (!isElement(el)) return 0;

  let height = el.getBoundingClientRect()?.height ?? 0;
  const computedStyle = window.getComputedStyle(el);
  height += parseFloat(computedStyle.marginTop);
  height += parseFloat(computedStyle.marginBottom);
  height += parseFloat(computedStyle.borderTopWidth);
  height += parseFloat(computedStyle.borderBottomWidth);
  return height;
}

export function getElementWidth(el) {
  if (!isElement(el)) return 0;

  let width = el.getBoundingClientRect()?.width ?? 0;
  const computedStyle = window.getComputedStyle(el);
  width += parseFloat(computedStyle.marginLeft);
  width += parseFloat(computedStyle.marginRight);
  width += parseFloat(computedStyle.borderLeftWidth);
  width += parseFloat(computedStyle.borderRightWidth);
  return width;
}

// Color functions
export function lightOrDark(colorInput) {
  if (!isString(colorInput)) return undefined;

  let color;
  let r;
  let g;
  let b;

  if (colorInput.match(/^rgb/)) {
    color = colorInput.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

    [r, g, b] = color;
  } else {
    color = +(`0x${colorInput.slice(1).replace(colorInput.length < 5 && /./g, '$&$&')}`);

    // eslint-disable-next-line no-bitwise
    r = color >> 16;
    // eslint-disable-next-line no-bitwise,no-mixed-operators
    g = color >> 8 & 255;
    // eslint-disable-next-line no-bitwise
    b = color & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(
    0.299 * (r * r)
    + 0.587 * (g * g)
    + 0.114 * (b * b),
  );

  return hsp > 127.5
    ? 'light'
    : 'dark';
}

// Sticky functions
export function stickyOffset() {
  // @todo @refactor STICKY GLOBAL Sticky global disabled for User tests
  return 0;
  // const header = document.getElementById('app-header');
  // let headerHeight = 0;
  // const extra = 20;
  //
  // if (header) {
  //   headerHeight = header.offsetHeight;
  // }
  //
  // return (headerHeight + extra);
}

export function stickyConfiguratorOffset() {
  const header = document.getElementById('app-header');
  const progressBar = document.getElementById('config-progress-bar');
  let headerHeight = 0;
  let progressBarHeight = 0;
  const extra = 20;

  if (header && progressBar) {
    headerHeight = header.offsetHeight;
    progressBarHeight = progressBar.offsetHeight;
  }

  return (headerHeight + progressBarHeight + extra);
}

// Regex testing
export function isUrl(url = '') {
  const urlRegex = /^(?:http(s)?:\/\/)[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#%[\]@!$&'()*+,;=]+$/;
  return typeof url === 'string'
    ? urlRegex.test(url)
    : false;
}

export function isPath(path = '') {
  const pathRegex = /^(?:\/)?(\/[a-zA-Z0-9._=#%&?-]*)+(\/?)$/;
  return typeof path === 'string'
    ? pathRegex.test(path)
    : false;
}

export function isTranslation(string = '') {
  const translationRegex = /^([a-zA-Z]+\.)+[a-zA-Z]+$/;
  return typeof string === 'string'
    ? translationRegex.test(string)
    : false;
}

export function translate(string = '') {
  if (!isTranslation(string)) return string;

  const translatedText = i18n.tc(string);

  return !isTranslation(translatedText) && !!translatedText ? translatedText : '';
}

// Redirect
export function redirectToUrl(url = '') {
  if (!isUrl(url)) throw new Error('Invalid URL');
  window.location.replace(url);
}

export async function handleUrlRouting(url = '', routeMethod = ROUTER_METHODS.push) {
  try {
    let routerData = { name: ROUTES.home };
    let internalRedirect = true;

    if (url) {
      if (isPath(url)) {
        routerData = url;
      }

      if (isUrl(url)) {
        const currentHost = window.location.hostname;
        const newUrl = new URL(url);
        const newHost = newUrl.hostname;

        if (currentHost === newHost) {
          routerData = newUrl.pathname;
        } else {
          internalRedirect = false;
        }
      }
    }

    if (internalRedirect) {
      const router = await getClientRouter();
      const method = Object.values(ROUTER_METHODS).includes(routeMethod) ? routeMethod : ROUTER_METHODS.push;

      await router[method](routerData);
    } else {
      redirectToUrl(url);
    }
  } catch (error) {
    consoleLog(error);
    const router = await getClientRouter();
    router.push({ name: ROUTES.home });
  }
}

// Copy text to clipboard
export function copyInputToClipboard(inputId = '') {
  const copyText = document.getElementById(inputId).getElementsByTagName('input')[0];
  copyText.select();
  document.execCommand('copy');
}

// Cookies
export function setCookie(name, value, expires) {
  const expirationDate = new Date(expires)?.toUTCString?.();
  document.cookie = `${name}=${value};expires=${expirationDate}; path=/`;
}

export function readCookie(name) {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

// File extension
export function getFileExtension(string) {
  if (typeof string !== 'string') return null;

  const matchResult = string.match(/\.([0-9a-z]+)(?:[?#]|$)/i);

  return (Array.isArray(matchResult) && matchResult.length === 2)
    ? matchResult[1]
    : null;
}

// Sleep and Wait function
export function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export function waitFor(readyReference = { ready: false }, waitTime = 100, maxTries = 10) {
  let attempt = 1;

  return new Promise((resolve) => {
    const check = () => {
      if (readyReference.ready || attempt > maxTries) {
        resolve();
      } else {
        attempt += 1;
        setTimeout(check, waitTime);
      }
    };
    setTimeout(check, waitTime);
  });
}
