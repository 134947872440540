import sortBy from 'lodash/sortBy';
import consoleLog from '@/assets/js/helpers/console-log';

const filterItemSortFunction = (item) => item.label;

const StockCarsSearchStore = {
  state: {
    stockCarsFilterSections: {},
    stockCarsFilterCategories: {},
    stockCarsFilterGroups: {},
    stockCarsFilterItems: {},
    stockCarsSortByData: [],
    stockCarsTotalAvailable: 0,
    stockCarsTotalAvailableFiltered: 0,
    stockCarsActiveFilters: {},
    stockCarsActiveSortByKey: '',
  },
  getters: {
    getStockCarsFilterSections: (state) => state.stockCarsFilterSections,

    getStockCarsFilterSectionKeys: (state) => Object.keys(state.stockCarsFilterSections),

    getStockCarsFilterSectionByKey: (state) => (sectionKey) => state.stockCarsFilterSections[sectionKey] ?? null,

    getStockCarsFilterCategoriesBySectionKey: (state) => (
      filterKey,
    ) => {
      const sortFunction = (category) => category.sortOrder;

      return sortBy(Object.values(state.stockCarsFilterCategories)
        .filter((category) => category.filterKey === filterKey), [sortFunction]);
    },

    getStockCarsFilterGroupsByCategoryId: (state) => (
      categoryId,
    ) => Object.values(state.stockCarsFilterGroups)
      .filter((group) => group.categoryId === categoryId),

    getStockCarsFilterCategoryById: (state) => (categoryId) => state.stockCarsFilterCategories[categoryId],

    getStockCarsFilterGroupById: (state) => (groupId) => state.stockCarsFilterGroups[groupId],

    getStockCarsFilterItemsBySectionKey: (state) => (
      sectionKey,
    ) => sortBy(Object.values(state.stockCarsFilterItems)
      .filter((item) => item.filterKey === sectionKey && !item.category), [filterItemSortFunction]),

    getStockCarsFilterItemsByCategoryId: (state) => (
      categoryId,
    ) => sortBy(Object.values(state.stockCarsFilterItems)
      .filter((item) => item.category?.id === categoryId && !item.group), [filterItemSortFunction]),

    getStockCarsFilterItemsByGroupId: (state) => (
      groupId,
    ) => sortBy(Object.values(state.stockCarsFilterItems)
      .filter((item) => item.group?.id === groupId), [filterItemSortFunction]),

    getStockCarsFilterItemById: (state) => (
      itemId,
    ) => state.stockCarsFilterItems[itemId],

    getStockCarsActiveFilters: (state) => state.stockCarsActiveFilters,

    getStockCarsActiveFilterValues: (state) => {
      const activeFilterValues = {};

      Object.entries(state.stockCarsActiveFilters).forEach(([filterKey, filterItems]) => {
        const filterValues = filterItems.reduce((filterItemsArray, itemId) => {
          const item = state.stockCarsFilterItems[itemId];
          filterItemsArray.push(...item.filterValues);
          return filterItemsArray;
        }, []);

        activeFilterValues[filterKey] = [...new Set(filterValues)];
      });

      return activeFilterValues;
    },

    getStockCarsActiveFiltersCount: (state, getters) => {
      const activeFiltersArray = Object.values(getters.getStockCarsActiveFilters)
        .reduce((activeFilters, filters) => {
          activeFilters.push(...filters);
          return activeFilters;
        }, []);

      return activeFiltersArray.length;
    },

    getStockCarsTotalHits: (state) => state.stockCars?.length || 0,

    getStockCarsTotalAvailable: (state) => state.stockCarsTotalAvailable,

    getStockCarsTotalAvailableFiltered: (state) => state.stockCarsTotalAvailableFiltered,

    getStockCarsSortByData: (state) => state.stockCarsSortByData,

    getStockCarsActiveSortByKey: (state) => state.stockCarsActiveSortByKey,

    getStockCarsActiveSortByData: (state) => state.stockCarsSortByData?.find((item) => item.key === state.stockCarsActiveSortByKey),

    isStockCarFilterActive: (state) => ({
      filterKey = '',
      filterItemId = '',
    } = {}) => {
      if (!filterKey || !filterItemId) return false;
      if (!Array.isArray(state.stockCarsActiveFilters[filterKey])) return false;

      return state.stockCarsActiveFilters[filterKey].includes(filterItemId);
    },
  },
  mutations: {
    UPDATE_STOCK_CARS_FILTER_SECTIONS(state, stockCarsFilterSections = {}) {
      state.stockCarsFilterSections = stockCarsFilterSections;
    },
    UPDATE_STOCK_CARS_FILTER_CATEGORIES(state, stockCarsFilterCategories = {}) {
      state.stockCarsFilterCategories = stockCarsFilterCategories;
    },
    UPDATE_STOCK_CARS_FILTER_GROUPS(state, stockCarsFilterGroups = {}) {
      state.stockCarsFilterGroups = stockCarsFilterGroups;
    },
    UPDATE_STOCK_CARS_FILTER_ITEMS(state, stockCarsFilterItems = {}) {
      state.stockCarsFilterItems = stockCarsFilterItems;
    },
    UPDATE_STOCK_CARS_TOTAL_AVAILABLE(state, stockCarsTotalAvailable = 0) {
      state.stockCarsTotalAvailable = stockCarsTotalAvailable;
    },
    UPDATE_STOCK_CARS_TOTAL_AVAILABLE_FILTERED(state, stockCarsTotalAvailableFiltered = 0) {
      state.stockCarsTotalAvailableFiltered = stockCarsTotalAvailableFiltered;
    },
    UPDATE_STOCK_CARS_SORT_BY_DATA(state, stockCarsSortByData = []) {
      state.stockCarsSortByData = stockCarsSortByData;
    },
    UPDATE_STOCK_CARS_ACTIVE_SORT_BY_KEY(state, stockCarsActiveSortByKey = '') {
      state.stockCarsActiveSortByKey = stockCarsActiveSortByKey;
    },

    ADD_STOCK_CARS_ACTIVE_FILTER(state, {
      filterKey = '',
      filterItemId = '',
    } = {}) {
      if (!filterKey || !filterItemId) return;

      if (!Array.isArray(state.stockCarsActiveFilters[filterKey])) {
        this._vm.$set(state.stockCarsActiveFilters, filterKey, []);
      }

      if (!state.stockCarsActiveFilters[filterKey].includes(filterItemId)) {
        state.stockCarsActiveFilters[filterKey].push(filterItemId);
      }
    },

    REMOVE_STOCK_CARS_ACTIVE_FILTER(state, {
      filterKey = '',
      filterItemId = '',
    } = {}) {
      if (!filterKey || !filterItemId) return;

      const filtersArray = state.stockCarsActiveFilters[filterKey];

      if (!Array.isArray(filtersArray)) return;

      const filterIndex = filtersArray.findIndex((activeFilter) => activeFilter === filterItemId);
      if (filterIndex !== -1) filtersArray.splice(filterIndex, 1);

      if (!filtersArray.length) {
        this._vm.$delete(state.stockCarsActiveFilters, filterKey);
      }
    },

    RESET_STOCK_CARS_ACTIVE_FILTERS(state) {
      this._vm.$set(state, 'stockCarsActiveFilters', {});
    },
  },
  actions: {
    async initStockCarsFilter({ commit, dispatch }, { filterData = {} } = {}) {
      const sectionsData = {};
      const categoriesData = {};
      const groupsData = {};
      const itemsData = {};
      const addedItemNames = {};

      const handleSection = (sectionObj) => {
        const { key, label, type } = sectionObj;

        sectionsData[key] = { key, label, type };
      };

      const handleCategory = (categoryObj) => {
        if (!categoryObj) return;
        if (!categoriesData[categoryObj.id]) categoriesData[categoryObj.id] = categoryObj;
      };

      const handleGroup = (groupObj) => {
        if (!groupObj) return;
        if (!groupsData[groupObj.id]) groupsData[groupObj.id] = groupObj;
      };

      const handleItem = (itemObj) => {
        const currentItem = itemObj;
        currentItem.filterValues = [itemObj.key];
        delete currentItem.key;

        const itemExists = !!addedItemNames[currentItem.label];

        if (!itemExists) {
          addedItemNames[currentItem.label] = currentItem.id;
          itemsData[currentItem.id] = currentItem;
          return;
        }

        const existingItemId = addedItemNames[currentItem.label];
        const existingItem = itemsData[existingItemId];

        itemsData[addedItemNames[currentItem.label]] = {
          ...itemsData[existingItemId],
          filterValues: [
            ...existingItem.filterValues,
            ...currentItem.filterValues,
          ],
          count: existingItem.count + currentItem.count,
          filteredCount: existingItem.filteredCount + currentItem.filteredCount,
        };
      };

      Object.values(filterData)
        .forEach((filterContent) => {
          handleSection(filterContent);

          filterContent.data.forEach((data) => {
            const { category, group } = data;
            handleCategory(category);
            handleGroup(group);
            handleItem(data);
          });
        });

      commit('UPDATE_STOCK_CARS_FILTER_SECTIONS', sectionsData);
      commit('UPDATE_STOCK_CARS_FILTER_CATEGORIES', categoriesData);
      commit('UPDATE_STOCK_CARS_FILTER_GROUPS', groupsData);
      commit('UPDATE_STOCK_CARS_FILTER_ITEMS', itemsData);

      await dispatch('setStockCarsActiveSortByKey', { refresh: false });
    },

    async toggleStockCarsFilter({ getters, commit, dispatch }, {
      filterKey = '',
      filterItemId = '',
      toggleOff = true,
      updateQuery = true,
      refresh = true,
    } = {}) {
      if (!filterKey || !filterItemId) return;

      const removeFilter = getters.isStockCarFilterActive({ filterKey, filterItemId }) && toggleOff;

      removeFilter
        ? commit('REMOVE_STOCK_CARS_ACTIVE_FILTER', { filterKey, filterItemId })
        : commit('ADD_STOCK_CARS_ACTIVE_FILTER', { filterKey, filterItemId });

      if (updateQuery) consoleLog('@todo toggleStockCarsFilter UPDATE QUERY');
      if (refresh) await dispatch('fetchAndSetStockCars', { manageLoader: true, forceUpdate: true });
    },

    async resetStockCarsActiveFilters({ commit, dispatch }, {
      overrideQuery = true,
      refresh = true,
    } = {}) {
      commit('RESET_STOCK_CARS_ACTIVE_FILTERS');

      if (overrideQuery) consoleLog('@todo resetStockCarsActiveFilters OVERRIDE QUERY');
      if (refresh) await dispatch('fetchAndSetStockCars', { manageLoader: true, forceUpdate: true });
    },

    async setStockCarsActiveSortByKey({ getters, commit, dispatch }, {
      sortByKey = '',
      refresh = true,
    } = {}) {
      const activeKey = getters.getStockCarsActiveSortByKey;
      if (sortByKey === activeKey) return;
      if (!sortByKey && activeKey) return;

      const sortByData = getters.getStockCarsSortByData || [];
      let sortByItem = sortByData?.find?.((item) => item.key === sortByKey);

      if (!sortByItem && !getters.getStockCarsActiveSortByKey) {
        sortByItem = sortByData?.[0];
      }

      const { key } = sortByItem ?? {};

      commit('UPDATE_STOCK_CARS_ACTIVE_SORT_BY_KEY', key);

      if (refresh) await dispatch('fetchAndSetStockCars', { manageLoader: true, forceUpdate: true });
    },
  },
};

export default StockCarsSearchStore;
